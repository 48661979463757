<template>
  <div class="knowledge-base-add-component regulation">
    <div class="knowledge-base-add-component__header">
      <div
        @click="$store.commit('changeCreateMode', false)"
        class="knowledge-base-add-component__to-back"
      >
        <i class="icon-left"></i>
        <span>Назад</span>
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div v-show="!loading" class="knowledge-base-add-component__container">
      <Widget type="text-editor" :unique="'add-regulation-editor'" />
      <div class="knowledge-base-add-component__settings">
        <h3 class="knowledge-base-add-component__settings__title">Параметры</h3>
        <CategoryPicker keyValue="documentTypeCreateRegulation" :isAdmin="isAdmin" />
        <Widget
          :style="{ marginBottom: 20 + 'px' }"
          type="input-title"
          placeholder="Название"
          :unique="'regulation-add-name'"
          maxlength="100"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'Author'"
          type="select-new"
          :maxCount="1"
          placeholder="Автор"
          label="Автор"
          :editValue="regulationAuthor"
          :hiddenItems="regulationCoAuthors"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'Co-Author'"
          type="select-new"
          :multiple="true"
          :maxCount="3"
          placeholder="Соавтор"
          label="Соавтор"
          :editValue="regulationCoAuthors"
          :hiddenItems="regulationAuthor"
        />
        <Widget
          :entity="'Tag'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'Tag'"
          type="select-new"
          :multiple="true"
          :maxCount="10"
          placeholder="Теги"
          label="Теги"
          :createMode="true"
        />
        <div class="knowledge-base-add-component__job-folder" v-if="isGuide">
          <div class="label">
            <span class="text">Связь с должностной папкой</span>
            <i class="icon-info"></i>
            <p class="tooltip-reg">
              У указанных ролей документ будет отображаться в должностной папке, даже если не
              прикреплен ни к какой задаче
            </p>
          </div>
          <Widget
            :entity="'Role'"
            :actionEntity="'getItems'"
            :itemName="'name'"
            :keyValue="'projectRoleCreateRegulation'"
            type="select-new"
            placeholder="Выберите проектную роль(и)"
            multiple="true"
          />
        </div>
        <div class="knowledge-base-add-component__responsibilities" v-if="isInstruction">
          <div class="label">
            <span class="text">Связь с задачей</span>
            <i class="icon-info"></i>
            <p class="tooltip-reg">У указанных ролей документ будет отображаться в их задачах</p>
          </div>
          <Widget
            :entity="'Responsibility'"
            :actionEntity="'actionGetItems'"
            :itemName="'name'"
            :keyValue="'projectRoleCreateResponsibilities'"
            type="select-new"
            placeholder="Выберите задачу(и)"
            multiple="true"
            :isAdminRegulation="isAdmin"
            :isRegulation="true"
          />
        </div>
        <div class="knowledge-base-add-component__settings__files">
          <i class="icon-paper-clip"></i>
          <FileUploaderCalendar
            label="Прикрепить файл"
            acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
            v-bind="{
              multiple: true,
              drop: false,
              dropDirectory: false,
              fileDrop: false,
              lengthText: 5,
              showOnlyFiles: true
            }"
            @changeFilesFromUploader="changeFilesFromUploader"
          />
        </div>
        <div class="knowledge-base-add-component__settings__links">
          <i class="icon-chain"></i>
          <Widget type="links-widget" :unique="'regulation-add-links'" />
        </div>
        <div class="knowledge-base-add-component__settings__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit('button:click_regulation-add-cancel')"
          />
          <ModalButton :title="'Добавить'" @handleButton="createChunksPoolFiles" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";

import linksUploaderMixin from "@/components/Widgets/LinksWidget/linksUploader.mixin";
import axios from "axios";
import { domain } from "@/globalVariables";
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";

const Widget = () => import("@/components/Widgets/Widget");
const FileUploaderCalendar = () =>
  import("@/components/Widgets/FileUploaderCalendar/FileUploaderCalendar");
const Textarea = () => import("@/components/Widgets/Textarea/Textarea");
const TabsWidget = () => import("@/components/Widgets/TabsWidget/TabsWidget");
const CategoryPicker = () =>
  import("@/components/KnowledgeBaseComponents/Regulation/CategoryPicker");
const TabsWidgetSecond = () => import("@/components/Widgets/TabsWidget/TabsWidget2.0");

export default {
  name: "RegulationAddComponent",
  mixins: [uploadFilesMixin],
  props: [],
  components: {
    ModalButton,
    TabsWidgetSecond,
    CategoryPicker,
    TabsWidget,
    Textarea,
    FileUploaderCalendar,
    Widget,
    linksUploaderMixin
  },
  data() {
    return {
      loading: false,
      editor: null,
      regulationName: "",
      regulationAuthor: [],
      regulationCoAuthors: [],
      regulationCategory: null,
      regulationRole: [],
      regulationTags: [],
      regulationLinks: [],
      regulationResponsibilities: [],
      content: "",
      preview: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_regulation-add-cancel");
    this.$root.$off("text-editor:change_add-regulation-editor");
    this.$root.$off("text:change_regulation-add-name");
    this.$root.$off("changeSelectValue:Author");
    this.$root.$off("changeSelectValue:Co-Author");
    this.$root.$off("changeSelectValue:Category");
    this.$root.$off("links:change_regulation-add-links");
    this.$root.$off("changeSelectValue:projectRoleCreateRegulation");
    this.$root.$off("changeSelectValue:projectRoleCreateResponsibilities");
    this.$root.$off("changeSelectValue:Tag");
    this.$root.$off("button:click_regulation-add-create");
    this.$root.$off("changeSelectValue:documentTypeCreateRegulation");
  },
  mounted() {
    this.$root.$on("text-editor:change_add-regulation-editor", (value) => {
      this.content = value.value;
      this.preview = value.preview;
    });
    this.$root.$on("text:change_regulation-add-name", (value) => {
      this.regulationName = value;
    });
    this.$root.$on("changeSelectValue:Author", (value) => {
      this.regulationAuthor = value;
    });
    this.$root.$on("changeSelectValue:Co-Author", (value) => {
      this.regulationCoAuthors = value;
    });
    this.$root.$on("changeSelectValue:Category", (value) => {
      this.regulationCategories = value;
    });
    this.$root.$on("changeSelectValue:Tag", (value) => {
      this.regulationTags = value;
    });
    this.$root.$on("links:change_regulation-add-links", (value) => {
      this.regulationLinks = value;
    });
    this.$root.$on("button:click_regulation-add-cancel", () => {
      this.$store.commit("changeCreateMode", false);
    });
    this.$root.$on("button:click_regulation-add-create", () => {
      this.createChunksPoolFiles();
    });
    this.$root.$on("changeSelectValue:projectRoleCreateRegulation", (value) => {
      this.regulationRole = value;
    });
    this.$root.$on("changeSelectValue:projectRoleCreateResponsibilities", (value) => {
      this.regulationResponsibilities = value;
    });
    this.$root.$on("changeSelectValue:documentTypeCreateRegulation", (value) => {
      this.regulationCategory = value.length ? value[0].name : null;
    });
    if (this.getDataInfoUser) {
      this.regulationAuthor = [this.getDataInfoUser];
    }
  },
  methods: {
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      if (!this.fileUploader.length) {
        this.createRegulationItem();
        return null;
      }
      this.createPull(this.fileUploader);
    },
    createRegulationItem() {
      httpClient({
        url: `/Regulation/actionCreate`,
        method: "POST",
        data: [
          {
            name: this.regulationName,
            author_user_id: this.regulationAuthor[0].id,
            co_authors: this.regulationCoAuthors.map((i) => {
              return i.id;
            }),
            tags: this.regulationTags.map((i) => {
              return i.id;
            }),
            type: this.regulationCategory,
            roles: this.isGuide
              ? this.regulationRole.map((item) => {
                  return item.id;
                })
              : undefined,
            responsibilities: this.isInstruction
              ? this.regulationResponsibilities.map((item) => {
                  return item.id;
                })
              : undefined,
            links: this.regulationLinks,
            content: this.content,
            preview: this.preview,
            attachment_files: this.fileUploadingId
          }
        ]
      })
        .then(() => {
          this.fileUploadingId = [];
          this.fileUploader = [];
          this.loading = false;
          this.clearFields();
          this.$store.commit("changeCreateMode", false);
          this.$emit("updateRegulationsPage");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Документ успешно добавлен!`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filesUploaded() {
      this.createRegulationItem();
    },
    changeFilesFromUploader(files) {
      this.fileUploader = files;
    },
    checkValidationData() {
      if (!this.regulationCategory) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите категорию!`
        });
        return false;
      }
      if (this.isInstruction && !this.isAdmin && !this.regulationResponsibilities.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Обязательно выберите связь с задачей!`
        });
        return false;
      }
      if (this.isGuide && !this.regulationRole.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Обязательно выберите связь с должностной папкой!`
        });
        return false;
      }
      if (!this.regulationName.trim().length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите название документа!`
        });
        return false;
      }
      if (this.regulationName.trim().length > 100) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название документа не может превышать 100 символов!`
        });
        return false;
      }
      if (!this.regulationAuthor.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Выберите автора!`
        });
        return false;
      }
      if (!this.regulationTags.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Должен быть выбран минимум 1 Тег!`
        });
        return false;
      }
      if (this.fileUploader.length > 10) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Максимальное кол-во файлов 10 !`
        });
        return false;
      }
      if (
        !this.content.replaceAll("&nbsp;", "").trim() ||
        !this.content.replaceAll("<p></p>", "").trim() ||
        !this.content.replaceAll("<p><br></p>", "").trim()
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите текст документа!`
        });
        return false;
      }
      return true;
    },

    clearFields() {
      this.regulationName = "";
      this.regulationAuthor = [];
      this.regulationCoAuthors = [];
      this.regulationCategory = null;
      this.regulationRole = [];
      this.regulationTags = [];
      this.regulationLinks = [];
      this.regulationResponsibilities = [];
      this.content = "";
      this.preview = "";
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    },

    isGuide() {
      return this.regulationCategory === "guide" ? true : false;
    },

    isInstruction() {
      return this.regulationCategory === "instruction" ? true : false;
    },

    isAdmin() {
      return (
        this.getDataInfoUser.roles.includes("personnel_officer") ||
        this.getDataInfoUser.roles.includes("infrastructure_admin")
      );
    }
  },
  watch: {
    regulationRole() {
      if (this.regulationRole.length) {
        this.$root.$emit("tabs:block_setAvailabilityRegulationCreate", 1);
      } else {
        this.$root.$emit("tabs:block_setAvailabilityRegulationCreate", 0);
      }
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.knowledge-base-add-component {
  &--book {
    display: flex;
    justify-content: center;
  }

  &__bg {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__header {
    margin-bottom: 17px;
  }

  &__to-back {
    display: flex;
    align-items: center;
    max-width: max-content;
    user-select: none;
    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    transition: all 0.2s ease;
    cursor: pointer;

    .icon-left {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15%;
  }

  &__container {
    display: flex;

    @media screen and (max-width: $lg) {
      flex-flow: column-reverse;
      align-items: flex-start;
    }
  }

  &__settings {
    width: 100%;
    height: 85vh;
    max-width: 480px;
    margin-left: 20px;
    padding: 29px 32px 20px 32px;
    overflow: scroll;
    border-radius: 24px;
    background-color: $color-white;

    @media screen and (max-width: $lg) {
      max-width: 100%;
      height: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }

    &__title {
      margin: 0 0 20px;
      font-weight: 500;
      color: $color-text-accent;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .select-bg {
        margin-bottom: 20px;
        width: 100%;

        .select-content-bg {
          height: 34px;
        }

        .form-group__textarea {
          height: 160px;
        }
      }
    }

    &__files {
      display: flex;
      margin-bottom: 20px;

      .icon-paper-clip {
        margin-right: 11px;
        font-size: 20px;
        color: $icon-subdued;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
          font-size: 20px;
          color: $icon-subdued;
          margin-right: 8px;
        }

        .uploader-label {
          font-size: 14px;
          line-height: 20px;
          color: $text-subdued;
          font-weight: 400;
        }

        .uploader-clear {
          margin-left: auto;
          color: $text-default;
          padding: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          cursor: pointer;

          &:hover {
            background: $action-secondary-hovered;
            border-radius: 4px;
          }
        }
      }
    }

    .knowledge-base-add-component__settings__wrap {
      width: 100%;
    }

    &__links {
      display: flex;
      margin-bottom: 20px;

      .icon-chain {
        font-size: 20px;
        color: $icon-subdued;
        margin: 3px 8px 0 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      @media screen and (max-width: $sm) {
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        margin-top: 20px;
      }

      .button-widget {
        margin-right: 15px;

        @media screen and (max-width: $sm) {
          width: 100%;
          order: 2;
          margin: 0;

          /deep/ {
            button {
              width: 100%;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $sm) {
            order: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  /deep/ {
    .mark-select-bg {
      margin-bottom: 20px;
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  &__job-folder {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -100px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  &__responsibilities {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -80px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  /deep/ {
    .background-uploader .files-background .files-info,
    .background-uploader .files-background .upload-more,
    .links-widget__item,
    .links-widget__add-more {
      @media screen and (max-width: $sm) {
        width: 100%;
        margin: 5px 0 0;
      }
    }
  }
}
</style>
